import React, { useState, useEffect } from "react";
import commonColor from "../../../theme/commonColor";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import Transactions from "./transactions";
import SwotWallet from "./swotWallet";
import {
  getTutorPaymentTransactions,
  getTutorEarningReport,
  getTutorTeachingPercentages
} from "../../../services/api";
import TutorStatistics from "./tutorStatistics";
import { ReactComponent as StatisticIcon } from "../../../assets/images/profile/statistics.svg";
import { ReactComponent as SwotWalletIcon } from "../../../assets/images/profile/swotwallet.svg";
import { ReactComponent as TransactionIcon } from "../../../assets/images/profile/transactions.svg";
import { ReactComponent as TutortypeIcon } from "../../../assets/images/profile/tutor-type.svg";
import TutorTypePanel from "./tutorTypePanel";
import SubscriptionBoard from "./subscriptionBoard";
const WalletPanel = ({ user, saveUser }) => {
  const [topSelected, setTopSelected] = useState("Subscription");
  const [transactions, setTransactions] = useState([]);
  const [earnings, setEarnings] = useState([]);
  const [teachingPercents, setTeachingPercents] = useState([]);

  useEffect(() => {
    if (user?.ngoUser) {
      setTopSelected("TutorType");
    }
  }, [user]);

  useEffect(() => {
    getTransactions();
    getEarnings();
    getTutorPercentages();
  }, []);

  const getTransactions = async () => {
    const res = await getTutorPaymentTransactions(user.uid);
    if (res?.data?.status && res.data.status === "SUCCESS") {
      setTransactions(res.data.data ?? []);
    }
  };

  const getEarnings = async () => {
    const res = await getTutorEarningReport(user.uid);
    if (res?.data?.status && res.data.status === "SUCCESS") {
      setEarnings(res.data.data ?? []);
    }
  };

  const getTutorPercentages = async () => {
    const result = await getTutorTeachingPercentages(user.uid);
    if (result) {
      console.log(
        "TutorTypePanel - useEffect - got tutor Percentages",
        result.data
      );
      setTeachingPercents(result.data);
    }
  };

  const renderTopSelectedTab = () => {
    if (topSelected === "Subscription") {
      return (
        <div className="w-full h-full flex justify-center items-center py-4 px-1">
          <SubscriptionBoard user={user} />
        </div>
      );
    } else if (topSelected === "Transactions") {
      return (
        <div className="h-full w-full flex justify-center items-center">
          <Transactions user={user} transactions={transactions} />
        </div>
      );
    } else if (topSelected === "Statistics") {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <TutorStatistics user={user} transactions={earnings} />
        </div>
      );
    } else if (topSelected === "TutorType") {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <TutorTypePanel user={user} teachingPercents={teachingPercents} />
        </div>
      );
    }
  };

  return (
    <div className="md:min-h-[600px] md:max-h-[600px] w-full px-1 flex flex-col justify-start pt-1">
      <div className="w-full flex justify-center heightBoxHeader">
        {!user?.ngoUser && (
          <>
            <button
              className="ml-4 w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
              style={{
                backgroundColor:
                  topSelected === "Subscription"
                    ? commonColor.deepPink
                    : "transparent",
                borderTopLeftRadius: "1.5rem",
                borderTopRightRadius: "1.5rem",
              }}
              onClick={() => setTopSelected("Subscription")}
            >
              <div className="flex justify-center items-center">
                <SwotWalletIcon className="w-4" />
                <p className="hidden ml-2 md:block">Subscription</p>
              </div>
            </button>
            {/* following code is commented becasue we might need it in future as this is an area under discussion */}
            {/* <button
              className="w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
              style={{
                backgroundColor:
                  topSelected === "Transactions"
                    ? commonColor.deepPink
                    : "transparent",
                borderTopLeftRadius: "1.5rem",
                borderTopRightRadius: "1.5rem",
              }}
              onClick={() => setTopSelected("Transactions")}
            >
              <div className="flex justify-center items-center">
                <TransactionIcon className="w-4" />
                <p className="hidden ml-2 md:block">Transactions</p>
              </div>
            </button> */}

            {/* <button
              className="w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
              style={{
                backgroundColor:
                  topSelected === "Statistics"
                    ? commonColor.deepPink
                    : "transparent",
                borderTopLeftRadius: "1.5rem",
                borderTopRightRadius: "1.5rem",
              }}
              onClick={() => setTopSelected("Statistics")}
            >
              <div className="flex justify-center items-center">
                <StatisticIcon className="w-4" />
                <p className="hidden ml-2 md:block">Statistics</p>
              </div>
            </button> */}
          </>
        )}

        <button
          className="w-1/5 sm:w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
          style={{
            backgroundColor:
              topSelected === "TutorType"
                ? commonColor.deepPink
                : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setTopSelected("TutorType")}
        >
          <div className="flex justify-center items-center">
            <TutortypeIcon className="w-4" />
            <p className="hidden ml-2 md:block">
              {user.accountType === "Tutor" ? "Tutor Type" : "Learner Type"}
            </p>
          </div>
        </button>
      </div>
      <div
        // className="flex-grow w-full bg-white darker_shadow h-full md:h-[calc(100%-3rem)] mt-3.5"
        className="w-full md:min-h-[600px] md:max-h-[600px] bg-white darker_shadow overflow-y-scroll flex-grow heightBoxContent"
        style={{
          borderRadius: "1.5rem",
        }}
      >
        {renderTopSelectedTab()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletPanel);
