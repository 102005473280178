import React, { useState, useEffect } from "react";
import commonColor from "../../../theme/commonColor";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import { PieChart, Pie, Cell } from "recharts";
import { getTutorTeachingPercentages } from "../../../services/api";
import FileViewer from "react-file-viewer";
import Swotbutton from "../../common/buttons/swotbutton";
import { ReactComponent as TutortypeIcon } from "../../../assets/images/profile/tutor-type.svg";
import { isMobileDevice, useWindowSize } from "../../../utils/uiUtils";
import Modal from "../../common/modal/modal";
// import { Document, Page } from 'react-pdf';
import { Document, Page } from "react-pdf";
// import { Document } from "react-pdf/dist/entry.webpack";

const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = [
  commonColor.deepGreen,
  commonColor.sourCherry,
  commonColor.deepBlue,
  commonColor.violet,
];

const TutorTypePanel = ({ user, teachingPercents }) => {
  const [topSelected, setTopSelected] = useState("TutorType");
  // const [teachingPercents, setTeachingPercents] = useState([]);
  const [pieData, setPieData] = useState();
  const [fullDetailsClicked, setFullDetailsClicked] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pieWidth, setPieWidth] = useState(200);
  const [width, height] = useWindowSize();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (width && width > 0) {
      if (width < 1024 && pieWidth !== 200) {
        setPieWidth(200);
      } else if(width >= 1024 && pieWidth !== 400){
        setPieWidth(400);
      }
    }
  }, [width]);

  const fullDetailsUrl =
    user.accountType === "Tutor"
      ? "https://flipbooksw.web.app/"
      : // "https://firebasestorage.googleapis.com/v0/b/swotknot.appspot.com/o/assets%2FtutorStyleDetails.pdf?alt=media&token=51e1fd7e-0e76-4cd7-86a6-3942acefdb99"
        "https://flipbook-learner.web.app/";
  const fullDetailPdfUrl =
  user.accountType === "Tutor" ? "https://firebasestorage.googleapis.com/v0/b/swotknot.appspot.com/o/assets%2FtutorStyleDetails.pdf?alt=media&token=51e1fd7e-0e76-4cd7-86a6-3942acefdb99.pdf"
   : "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/assets%2FlearnerStyle_student.pdf?alt=media&token=079e2151-0614-49ee-8213-e3ae0d111a71";

  useEffect(() => {
    if (teachingPercents && teachingPercents.length > 0) {
      const pie = [
        {
          name: teachingPercents[0]?.type,
          value: teachingPercents[0]?.percentage,
        },
        {
          name: teachingPercents[1]?.type,
          value: teachingPercents[1]?.percentage,
        },
        {
          name: teachingPercents[2]?.type,
          value: teachingPercents[2]?.percentage,
        },
        {
          name: teachingPercents[3]?.type,
          value: teachingPercents[3]?.percentage,
        },
      ];

      setPieData(pie);

      //Hint: This is to fix rendering issue on iOS. Dont remove this.
      setTimeout(() => {
        setPieData(
          pie.map((p) => {
            return { name: p.name, value: p.value };
          })
        );
      }, 500);
    }
  }, [teachingPercents]);

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  // const fileViewer = () => {
  //   return (
  //     <div>
  //       <Document file={fullDetailPdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
  //         <Page pageNumber={pageNumber} />
  //       </Document>
  //       <p>
  //         Page {pageNumber} of {numPages}
  //       </p>
  //     </div>
  //   );
  // };

  const renderTopSelectedTab = () => {
    if (topSelected === "TutorType") {
      return (
        <div className="w-full min-h-40 flex flex-col md:justify-around items-center relative">
          <Modal
            isOpen={!!fullDetailsClicked}
            onRequestClose={() => {
              setFullDetailsClicked(false);
            }}
            // className="w-full-f h-full-f"
          >
            {fullDetailsClicked && (
              // <div className="w-full h-full max-h-full text-center">
              <div className="w-80v md:w-70v lg:w-50v h-70v lg:h-80v text-center">
                {/* {fileViewer()} */}
                <FileViewer
                  fileType={"pdf"}
                  filePath={fullDetailPdfUrl}
                  onError={onError}
                />
              </div>
            )}
          </Modal>

          <div className="flex flex-col lg:flex-row items-center">
            {pieData && (
              <PieChart width={pieWidth} height={pieWidth}>
                <Pie
                  data={pieData}
                  dataKey="value"
                  // cx={"50%"}
                  // cy={"50%"}
                  // outerRadius={40}
                  fill="#8884d8"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            )}
            {teachingPercents.length > 0 ? (
              <div className="md:px-8">
                {teachingPercents.slice(0, 4).map((p, index) => (
                  <div
                    key={p.type}
                    className="text-sm p-1 flex justify-start items-center"
                  >
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{
                        backgroundColor: COLORS[index],
                      }}
                    />
                    <div className="text-sm px-2">
                      {p.type} {p.percentage.toFixed(0)}%
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="h-1/2 w-full text-center align-middle italic">
                Fetching learning style details...
              </div>
            )}
          </div>
          {pieData && (
            <div className="w-full px-6 mb-5 flex justify-end">
              <Swotbutton
                className="md:mx-auto"
                text={"Full details"}
                onClick={() => {
                  console.log("Full details pressed!");
                  if (isMobileDevice()) {
                  //   // setFullDetailsClicked(true);
                    window.open(fullDetailPdfUrl, "_blank", "fullscreen=yes");
                  } else {
                    window.open(fullDetailsUrl, "_blank");
                  }
                }}
              />
            </div>
          )}
        </div>
      );
    }
  };

  return <div className="h-full w-full px-2">{renderTopSelectedTab()}</div>;

  // return (
  //   <div className="h-full w-full px-2">
  //     <div
  //       className="w-full flex justify-start items-end pl-2"
  //       style={{
  //         height: "3rem",
  //       }}
  //     >
  //       <button
  //         className="pt-2 px-6 pb-1 mx-3 text-center text-white text-sm focus:outline-none"
  //         style={{
  //           backgroundColor:
  //             topSelected === "TutorType"
  //               ? commonColor.deepPink
  //               : "transparent",
  //           borderTopLeftRadius: "1.5rem",
  //           borderTopRightRadius: "1.5rem",
  //         }}
  //         onClick={() => setTopSelected("TutorType")}
  //       >
  //         <div className="flex justify-center items-center">
  //           <TutortypeIcon className="w-4" />
  //           <p className="hidden ml-2 md:block">
  //             {user.accountType === "Tutor" ? "Tutor Type" : "Learner Type"}
  //           </p>
  //         </div>
  //       </button>
  //     </div>
  //     <div
  //       className="w-full bg-white darker_shadow h-17/20"
  //       style={{
  //         borderRadius: "1.5rem",
  //         // height: "85%",
  //       }}
  //     >
  //       {renderTopSelectedTab()}
  //     </div>
  //   </div>
  // );
};

export default TutorTypePanel;
// const mapStateToProps = (state) => ({
//   user: state.user.user,
// });

// const mapDispatchToProps = (dispatch) => ({
//   saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(TutorTypePanel);
