import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../landing/landingLayout.css";
import commonColor from "../../theme/commonColor";
import LearnLogo from "../../assets/images/learn-logo-colour.svg";
import { useWindowSize } from "../../utils/uiUtils";
import { ReactComponent as Facebook } from "../../assets/icons/facebook (13).svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram (5).svg";
import { ReactComponent as Pinterest } from "../../assets/icons/pinterest.svg";
import { ReactComponent as BloggerIcon } from "../../assets/icons/blogger-icon.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu (7).svg";
import LandingTitle from "../../components/landing/landingTitle";
import { TermsAndPrivacyPolicy } from "../common/consent";
import { CURRENT_RELEASE_MODE, ReleaseMode } from "../../config/constants";
import { isMobileDevice } from "../../utils/uiUtils";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { Helmet } from "react-helmet-async";
import HelmetDetaData from "../common/share/helmetMetaData";
import SEO from "../common/share/SEO";
import { historyState } from "../../services/navigation/history";

const LandingLayout = (props) => {
  const navigate = useNavigate();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [menuShown, setmenuShown] = useState();
  const [width, height] = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  const showComponent = props.showComponent;
  useEffect(() => {
    historyState.navigate = navigate;
  }, [navigate]);

  return (
    <div>
      <SEO />
      {/* <HelmetDetaData></HelmetDetaData> */}

      <div className=" w-full h-screen min-h-screen-ios flex lg:flex-row flex-col-reverse gradient-background">
        {props.hideMenu != true && (
          <div className="pt-1  lg:w-1/12 w-full lg:h-screen h-auto flex float-left flex-col justify-end items-center pb-2 bg-transparent">
            <div className="flex lg:flex-col flex-row justify-center items-center lg:gap-0 gap-10">
              <div className=" lg:z-20">
                <Link className="focus:outline-none" to="/blogs">
                  <BloggerIcon
                    className="h-8 w-8  mb-0 md:mr-0 -mr-3 social-icon"
                    style={{ width: "40px", height: "40px" }}
                  />
                  {/* <img src={BloggerIcon} className="h-8 w-8 blog-icon"
            style={{
            }}/> */}
                </Link>
              </div>
              <div className=" lg:z-20">
                <a
                  href="https://www.facebook.com/profile.php?id=100086232200064"
                  target="__blank"
                  className="focus:outline-none p-2"
                >
                  <Facebook className="h-8 w-8 social-icon" style={{}} />
                </a>
              </div>
              <div className=" lg:z-20">
                <a
                  href="https://www.instagram.com/l3arn_edu/"
                  target="__blank"
                  className="focus:outline-none p-2"
                >
                  <Instagram className="h-8 w-8 social-icon" />
                </a>
              </div>
              <div className=" lg:z-20">
                <a
                  href="https://www.pinterest.com/l3arn_edu/"
                  target="__blank"
                  className="focus:outline-none p-2"
                >
                  <Pinterest className="h-8 w-8 social-icon" />
                </a>
              </div>
            </div>
          </div>
        )}
        {/* Add Button Hover here */}
        <div className="relative w-full flex-row flex h-full float-right items-center overflow-auto md:p-0 p-1">
          {props.hideMenu != true && (
            <div className="menuBtn absolute hidden" style={{ left: "0.5rem" }}>
              <div
                className="group bg-white absolute rounded-lg p-2 shadow-lg z-50"
                style={{ top: "25%" }}
              >
                <button
                  className="focus:outline-none p-3 -m-2 glow rounded-lg"
                  onClick={() => {
                    isMobileDevice() && setmenuShown(true);
                  }}
                >
                  <Menu
                    style={{
                      fill: commonColor.deepGreen,
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                </button>

                <div
                  className={`${
                    isMobileDevice()
                      ? `${menuShown ? "flex open" : "hidden closed"}`
                      : "group-hover:flex hidden"
                  }  absolute bg-white rounded-lg p-2 flex-col justify-start shadow-lg`}
                  style={{
                    top: "0",
                    left: "0",
                    width: "15rem",
                    zIndex: "100",
                  }}
                >
                  <div className="bg-white rounded-lg p-3">
                    <button
                      className="focus:outline-none p-1"
                      onClick={() => {
                        isMobileDevice() && setmenuShown(false);
                      }}
                    >
                      <Menu
                        style={{
                          fill: commonColor.deepGreen,
                          width: "2rem",
                          height: "2rem",
                        }}
                      />
                    </button>
                  </div>

                  <div className="px-4 py-2">
                    <div className="py-1">
                      <Link
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        to="/"
                      >
                        Home
                      </Link>
                    </div>

                    <div className="py-1">
                      <Link
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        to="/what-we-offer"
                      >
                        What do we offer?
                      </Link>
                    </div>

                    {/* <div className="py-1">
                    <Link
                      to="/for-who"
                      className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                    >
                      For who?
                    </Link>
                  </div> */}

                    <div
                      onMouseLeave={() => setIsOpen(false)}
                      className="mb-1 ml-2"
                    >
                      <button
                        className="personaBtn font-medium focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        onMouseEnter={() => setIsOpen(true)}
                        data-state={menuShown ? "hide" : "show"}
                      >
                        For Who{isOpen ? "" : "..."}
                      </button>
                      <ul
                        className={` list-disc ml-2 menu-items ${
                          isOpen ? "open" : "closed"
                        }`}
                      >
                        {/* List Start */}
                        <li className="pt-0.5 pb-1 font-medium">
                          <Link
                            to="/st-learning-df"
                            className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                          >
                            Students
                          </Link>
                        </li>

                        <li className="pt-0.5 pb-1 font-medium">
                          <Link
                            to="/pr-children-learning-df"
                            className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                          >
                            Parents
                          </Link>
                        </li>

                        <li className="pt-0.5 pb-1 font-medium">
                          <Link
                            to="/for-tutors"
                            className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                          >
                            Tutors
                          </Link>
                        </li>
                        <li className="pt-0.5 pb-1 font-medium">
                          <Link
                            to="/exam-prep"
                            className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                          >
                            Exam Prep
                          </Link>
                        </li>

                        <li className="pt-0.5 pb-1 font-medium">
                          <Link
                            to="/home-schooling"
                            className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                          >
                            Home Schooling
                          </Link>
                        </li>

                        {/* List End */}
                      </ul>
                    </div>

                    {/* <div className="flex w-11/12 flex-col pl-2 ml-4">
                    <ul className="list-disc ">
                      <li className="pt-0.5 pb-1.5 font-medium">
                        <Link
                          to="/home-schooling"
                          className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        >
                          Home Schooling
                        </Link>
                      </li>
                      <li className="pt-0.5 pb-1.5 font-medium">
                        <Link
                          to="/st-learning-df"
                          className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        >
                          Student Learning Difficulties
                        </Link>
                      </li>
                      <li className="pt-0.5 pb-1.5 font-medium">
                        <Link
                          to="/exam-prep"
                          className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        >
                          Exam Prep
                        </Link>
                      </li>
                      <li className="pt-0.5 pb-1.5 font-medium">
                        <Link
                          to="/pr-children-learning-df"
                          className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        >
                          Parents with children with learning difficulties
                        </Link>
                      </li>
                      <li className="pt-0.5 pb-1.5 font-medium">
                        <Link
                          to="/for-tutors"
                          className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        >
                          Tutors
                        </Link>
                      </li>
                    </ul>
                  </div> */}

                    <div className="py-1">
                      {/*<Link
                      className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                      to="/what-we-are-building"
                    >
                      What are we building?
                    </Link> */}
                      <Link
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        to="/learner-type"
                      >
                        What is your learner type?
                      </Link>
                    </div>

                    <div className="py-1">
                      <Link
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        to="/information"
                      >
                        Information
                      </Link>
                    </div>
                    <div className="py-1">
                      <Link
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        to="/news"
                      >
                        Swotnews
                      </Link>
                    </div>
                    {/* <div className="py-1">
                  <Link
                    className="focus:outline-none"
                    to='/plans'
                  >
                    Subscription
                  </Link>
                </div> */}
                    {/* <div className="py-1">
                    <Link
                      to="/about-us"
                      className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                    >
                      Who we are
                    </Link>
                  </div> */}
                    <div className="py-1">
                      <Link
                        to="/the-team"
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                      >
                        The team
                      </Link>
                    </div>
                    <div className="py-1">
                      <Link
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                        to="/Jobs"
                      >
                        Jobs
                      </Link>
                    </div>
                    <div className="py-1">
                      <Link
                        to="/frequent-asked-questions"
                        className="focus:outline-none hover:text-secondary-500 text-sm md:text-base"
                      >
                        FAQ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="h-full w-full bg-white lg:pt-4 pt-2 lg:px-8 px-5 lg:pb-2 pb-1 flex flex-col lg:gap-4 gap-3"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <div
              className="w-full h-1/10 flex justify-between px-4 lg:px-8 bg-white"
              style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
              }}
            >
              <div className="flex items-center z-50">
                <img
                  src={LearnLogo}
                  alt="Logo"
                  className="w-32 bg-transparent cursor-pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
              {CURRENT_RELEASE_MODE !== ReleaseMode.BETA && (
                <div className=" flex items-center">
                  <Swotbutton
                    text={"Log In"}
                    onClick={() => navigate("/sign-in")}
                  />
                </div>
              )}
            </div>

            {/* <div className="2xl:flex h-9/10 overflow-y-scroll"> */}
            <div
              className={`overflow-hidden flex flex-col h-9/10 justify-center ${
                props?.bgColor ?? "bg-white"
              } ${props.notHome ? "" : "relative"}`}
              style={{
                boxShadow: `0 0px 10px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
              }}
            >
              <div
                className={`h-full overflow-x-hidden ${
                  !props.disableScroll
                    ? "overflow-y-scroll"
                    : "overflow-y-hidden"
                }`}
                style={{
                  borderRadius: 25,
                }}
              >
                <div
                  className={`w-full mb-4 ${
                    props.landingTitle ? "" : "hidden"
                  }`}
                >
                  <LandingTitle text={props.landingTitle} />
                </div>
                <div
                  className={`flex flex-col flex-grow ${
                    props.notHome ? "mx-2 pb-14" : "mx-0 pb-0 h-full"
                  } ${
                    props.disableScroll ? "Content-height" : ""
                  } lg:justify-start lg:items-center`}
                >
                  {props.children}
                </div>
                <div>
                  {!props.notHome && (
                    <div
                      className={`flex align-bottom h-16 items-end justify-center p-2 text-center text-sm`}
                    >
                      <TermsAndPrivacyPolicy />
                    </div>
                  )}
                </div>

                {/* <div
                  className={`flex align-bottom h-16 items-end justify-center p-2 text-center text-sm`}
                >
                  <TermsAndPrivacyPolicy />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingLayout;
