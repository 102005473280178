import React, { useEffect } from "react";
import { connect } from 'react-redux';
import UserActions from "../../redux/user";
import { updateUser, onBoardUser } from "../../services/userManagement";
import Wizard from "../../components/onBoarding/wizard";
import { useNavigate } from "react-router-dom";

const OnBoardingPage = ({ user, saveUser, signOut }) => {

  console.log("user onboarding page", user)

  //console.log("OnBoardingPage - user", user, saveUser)

  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.status === "active") {
      navigate("/dashboard");
    }
  }, [user]);


  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDFZO6X2-rBzRgKTzEageApu3R5Rj1JxkA&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const onBoardNewUser = async (user) => {
    const activeUser = { ...user };

    console.log("activeUser", activeUser);

    activeUser.status = "activation-pending"
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log("User timezone - ", tz);
    activeUser.timeZone = tz
    //saveUser(activeUser);
    const userSuccess = await onBoardUser(activeUser);
    console.log("onBoardNewUser", userSuccess)
    saveUser(userSuccess);
  }

  return (
    <Wizard onBoardNewUser={onBoardNewUser} navigate={navigate} />
  )
};

const mapStateToProps = (state) => ({
  user: state.user.user,

})

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) =>
    dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingPage);