import { Helmet } from "react-helmet-async";

export const TermsLink = () => (
  <a
    className="font-bold underline text-blue-800"
    href="/terms-and-conditions"
    target="__blank"
  >
    Terms and Conditions
  </a>
);

export const TermsAndPrivacyPolicy = () => (
  <div>
    Copyright © 2021-2024 l3arn -{" "}
    <a
      className="hover:text-[#3745a3]"
      href="/terms-and-conditions"
      target="__blank"
    >
      Terms & Conditions
    </a>{" "}
    /{" "}
    <a
      className="hover:text-[#3745a3]"
      href="/privacy-policy"
      target="__blank"
    >
      {" "}
      Privacy Policy
    </a>{" "}
    /{" "}
    <a
      className="hover:text-[#3745a3]"
      href="https://isbstudent.atlassian.net/servicedesk/"
      target="__blank"
    >
      Contact us
    </a>
  </div>
);

export const PrivacyPolicy = () => (
  <a
    className="font-bold underline text-blue-800"
    href="privacy-policy"
    target="__blank"
  >
    {" "}
    Privacy Policy
  </a>
);

export const PrivacyPolicyInculdingGDPR = () => (
  <a
    className="font-bold underline text-blue-800"
    href="/privacy-policy"
    target="__blank"
  >
    {" "}
    Privacy Policy, including GDPR
  </a>
);

export const GDPR = () => (
  <a
    className="font-bold underline text-blue-800"
    href="https://www.iubenda.com/en/help/5853-gdpr-protections-users"
    target="__blank"
  >
    {" "}
    GDPR
  </a>
);

export const TermsCheckbox = ({ checked, onChange, disabled }) => {
  return (
    <div className="flex justify-start items-center">
      <input
        type="checkbox"
        name="terms-and-conditions"
        checked={checked}
        onChange={onChange}
        style={{
          left: 0,
          width: "1rem",
          marginRight: "0.5rem",
          marginBottom: 0,
        }}
        disabled={disabled}
      />
    </div>
  );
};

export const ConsentScript = () => (
  <Helmet>
    <script type="text/javascript">
      {` var _iub = _iub || {}; 
  _iub.cons_instructions = _iub.cons_instructions || []; 
  _iub.cons_instructions.push(["init", {api_key: "5JG5BDtPXiNh5eidZntHkp4egH0NJqrd"}]);`}
    </script>
    <script
      type="text/javascript"
      src="https://cdn.iubenda.com/cons/iubenda_cons.js"
      async
    ></script>
  </Helmet>
);

export const submitConsent = (id, email, name) => {
  return;
};
