import React, { useEffect, useState } from "react";
import SwotknotLogo from "../../assets/images/learn-logo-colour.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import Swotbutton from "../../components/common/buttons/swotbutton";
import Animations from "../../components/onBoarding/progress/animations";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import { resendVerificationEmail } from "../../services/api";
import LoaderButton from "../../components/common/buttons/loaderbutton";
import commonColor from "../../theme/commonColor";

function EmailActivationPrompt(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);

  useEffect(() => {
    if (props.user && props.user.status === "active") {
      navigate("/dashboard");
    }
  }, [props.user]);

  const resendConfirmationEmail = async () => {
    const email = props.user.email;
    if (email) {
      setLoading(true);
      const data = await resendVerificationEmail(email);
      setLoading(false);
      if (data && data.status === 200) {
        setConfirmationSent(true);
      }
    }
  };

  return (
    <div className="w-full pt-18 flex flex-col">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="py-8">
          <img
            src={SwotknotLogo}
            alt="Logo"
            className="w-40 pt-2 bg-transparent"
          />
        </div>
        <div className="p-6">
          <p className="text-center text-sm">
            {"One more step to complete your subscription to l3arn."}
          </p>
          <p className="text-center text-sm">
            {
              "A confirmation email has been sent to the email account you have chosen to use for l3arn."
            }
          </p>
          <p className="text-center text-sm">
            {
              "Follow the link and start exploring the new way of online education."
            }
          </p>
        </div>
        <div className="py-8">
          <Swotbutton
            className={"px-4"}
            text={"Browse l3arn"}
            onClick={() => {
              props.saveUser(null);
              signOut(auth);
              navigate("/");
            }}
          />
        </div>
        <div className="py-8">
          {confirmationSent ? (
            <Swotbutton
              className={"px-4"}
              bgColor={commonColor.deepGreen}
              text={"Verification Email Sent"}
            />
          ) : loading ? (
            <LoaderButton bgColor={commonColor.deepGreen} shadow={true} />
          ) : (
            <Swotbutton
              className={"px-4"}
              bgColor={commonColor.deepGreen}
              text={"Resend verification Email"}
              onClick={resendConfirmationEmail}
            />
          )}
        </div>
        <div
          className="sm:w-1/4 w-2/4 max-w-lg mx-auto 
          flex bg-white flex-row justify-around items-center"
        >
          {/* <Animations currentStep={5} /> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailActivationPrompt);
