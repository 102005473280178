import { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import swotknotlogo from "../assets/learn-logo-colour.svg";

import { Link, animateScroll as scroll, Events } from "react-scroll";

import { ReactComponent as FacebookIcon } from "../assets/socialMedia/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/socialMedia/instagram.svg";
import { ReactComponent as PinterestIcon } from "../assets/socialMedia/pinterest.svg";
import { ReactComponent as BlogIcon } from "../assets/socialMedia/symbols.svg";
import { useWindowSize } from "../../../utils/uiUtils";
import { animated, useTransition } from "@react-spring/web";

const socials = [
  {
    id: "facebook",
    link: "https://www.facebook.com/profile.php?id=100086232200064",
    Icon: FacebookIcon,
  },
  {
    id: "instagram",
    link: "https://www.instagram.com/l3arn_edu/",
    Icon: InstagramIcon,
  },
  {
    id: "pinterest",
    link: "https://www.pinterest.com/l3arn_edu/",
    Icon: PinterestIcon,
  },
];

const SocialButton = ({ id, link, Icon }) => (
  <animated.div className="cursor-pointer">
    <a href={link} target="__blank">
      <Icon className="w-6 h-6 m-2" />
    </a>
  </animated.div>
);

const Menu = () => {
  const [width, height] = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(width <= 768);
  // const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const transitions = useTransition(isOpen, {
    from: { y: "-100%" },
    enter: { y: "0%", config: { duration: 350 } },
    leave: { y: "-100%", config: { duration: 350 } },
  });

  const handleClickScroll = (elementId, offset = 0) => {
    toggleMenu();

    const element = document.getElementById(elementId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {isMobile && !isOpen && (
        <button
          onClick={toggleMenu}
          className="fixed z-30 lg:hidden p-5 top-2 right-2"
          // variants={isMobileView ? null : variants}
        >
          <FaBars size={24} />
        </button>
      )}

      {isMobile ? (
        <>
          {!isOpen && (
            <button
              onClick={toggleMenu}
              className="lg:hidden p-5 absolute top-2 right-2 opacity-0"
              // variants={isMobileView ? null : variants}
            >
              <FaBars size={24} />
            </button>
          )}

          {transitions(
            (style, isOpen) =>
              isOpen && (
                <animated.div
                  className="fixed z-10 top-0 left-0 w-full h-full bg-white overflow-auto"
                  style={style}
                >
                  <button
                    onClick={toggleMenu}
                    className="p-5 absolute top-2 right-2"
                  >
                    <FaTimes size={24} />
                  </button>
                  <img
                    className="w-80 h-auto mb-14 md:mb-32 mr-4"
                    src={swotknotlogo}
                    alt="l3arn-logo"
                  />
                  <ul className="menu_links flex flex-col gap-6 mb-10 text-center text-gray-700">
                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("home_landing");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="px-2">
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("explore_learning", 200);
                        }}
                      >
                        Explore
                      </a>
                      {/* <Link
                        title="Join Now"
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        activeClass="active"
                        to="join_now"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        Join Now
                      </Link> */}
                    </li>

                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("educate", 100);
                        }}
                      >
                        Educate
                      </a>
                      {/* <Link
                        title="Innovation Hub"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="innovation_hub"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-200}
                        onClick={toggleMenu}
                      >
                        Innovation Hub
                      </Link> */}
                    </li>
                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("Enterprise", 100);
                        }}
                      >
                        Enterprise
                      </a>
                      {/* <Link
                        title="Your journey"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="your_journey"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={toggleMenu}
                      >
                        Your journey
                      </Link> */}
                    </li>
                    <li className="px-2">
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("unlock_potential");
                        }}
                      >
                        Engage
                      </a>
                      {/* <Link
                        title="Unlock your Potential"
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        activeClass="active"
                        to="unlock_potential"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                      >
                        Unlock your Potential
                      </Link> */}
                    </li>
                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("enroll", 100);
                        }}
                      >
                        Enroll
                      </a>
                      {/* <Link
                        title="Explore learning"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="explore_learning"
                        spy={true}
                        smooth={true}
                        duration={900}
                        offset={-100}
                        onClick={toggleMenu}
                      >
                        Explore learning
                      </Link> */}
                    </li>
                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("success_stories", 200);
                        }}
                      >
                        Experience
                      </a>
                      {/* <Link
                        title="Success Stories"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="success_stories"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-200}
                        onClick={toggleMenu}
                      >
                        Success Stories
                      </Link> */}
                    </li>
                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("blogs");
                        }}
                      >
                        Enlighten
                      </a>

                      {/* <Link
                        title="Blogs"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="blogs"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={toggleMenu}
                        onTouch={toggleMenu}
                      >
                        Blogs
                      </Link> */}
                    </li>
                    <li>
                      <a
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        onClick={() => {
                          handleClickScroll("your_tutors");
                        }}
                      >
                        Empower
                      </a>
                      {/* <Link
                        title="Your tutors"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="your_tutors"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={toggleMenu}
                      >
                        Your tutors
                      </Link> */}
                    </li>
                  </ul>
                  <div className="flex gap-6 justify-center mt-24">
                    <div className="social-links flex flex-wrap gap-2">
                      {socials.map((social) => (
                        <div key={social.id} className="">
                          <SocialButton key={social.id} {...social} />
                        </div>
                      ))}
                    </div>
                  </div>
                </animated.div>
              )
          )}
          {/* </AnimatePresence> */}
        </>
      ) : (
        <div className=" md:fixed md:flex justify-between items-center flex-col w-[20%] h-[100vh] pt-4 pb-4 border-r-2 border-r-[#9A3B65]">
          <img
            className="w-60 h-auto mb-14 md:mb-32 mt-4"
            src={swotknotlogo}
            alt="l3arn-logo"
          />
          <ul className=" menu_links flex flex-col gap-6 mb-20 text-left text-gray-700 px-2">
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("home_landing");
                }}
              >
                Home
              </a>

              {/* <Link
                title="Home"
                className="menu_link text-gray-500 text-sm cursor-pointer"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                duration={500}
              >
                Home
              </Link> */}
            </li>{" "}
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("explore_learning", 200);
                }}
              >
                Explore
              </a>
              {/* <Link
                        title="Join Now"
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        activeClass="active"
                        to="join_now"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        Join Now
                      </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("educate", 150);
                }}
              >
                Educate
              </a>
              {/* <Link
                        title="Innovation Hub"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="innovation_hub"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-200}
                        onClick={toggleMenu}
                      >
                        Innovation Hub
                      </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("enterprise", 100);
                }}
              >
                Enterprise
              </a>
              {/* <Link
                        title="Your journey"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="your_journey"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={toggleMenu}
                      >
                        Your journey
                      </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("unlock_potential");
                }}
              >
                Engage
              </a>
              {/* <Link
                        title="Unlock your Potential"
                        className="menu_link text-gray-500 text-sm cursor-pointer"
                        activeClass="active"
                        to="unlock_potential"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                      >
                        Unlock your Potential
                      </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("enroll", 100);
                }}
              >
                Enroll
              </a>
              {/* <Link
                        title="Explore learning"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="explore_learning"
                        spy={true}
                        smooth={true}
                        duration={900}
                        offset={-100}
                        onClick={toggleMenu}
                      >
                        Explore learning
                      </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("success_stories", 200);
                }}
              >
                Experience
              </a>
              {/* <Link
                title="Success Stories"
                className="menu_link text-gray-500 text-sm cursor-pointer"
                activeClass="active"
                to="success_stories"
                spy={true}
                smooth={true}
                duration={500}
                offset={-200}
              >
                Success Stories
              </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("blogs");
                }}
              >
                Enlighten
              </a>
              {/* <Link
                title="Blogs"
                className="menu_link text-gray-500 text-sm cursor-pointer"
                activeClass="active"
                to="blogs"
                spy={true}
                smooth={true}
                duration={500}
                offset={-100}
              >
                Blogs
              </Link> */}
            </li>
            <li className="px-2">
              <a
                className="menu_link text-gray-500 text-sm cursor-pointer"
                onClick={() => {
                  handleClickScroll("your_tutors");
                }}
              >
                Empower
              </a>
              {/* <Link
                        title="Your tutors"
                        className="menu_link text-gray-500 text-sm"
                        activeClass="active"
                        to="your_tutors"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                        onClick={toggleMenu}
                      >
                        Your tutors
                      </Link> */}
            </li>
          </ul>
          <div className="flex gap-6 mt-14">
            <div className="social-links flex flex-wrap gap-2">
              {socials.map((social) => (
                <div key={social.id} className="flex flex-wrap gap-2">
                  <SocialButton key={social.id} {...social} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
