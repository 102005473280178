import React, { useState, useEffect } from "react";
import UserActions from "../../redux/user";

import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../redux/redux-models/redux_state";
import UserModel from "../../redux/redux-models/reduxUser";
import { ChatService } from "../services/chatService";
import { IChatGroup, IChatMessage } from "../models/chat_models";
import ChatListComponent from "./ChatListComponent";
import ActiveChatComponent from "./activeChat/ActiveChatComponent";
import FooterView from "../../components/common/footerView";
import BoxLoader from "../../components/common/BoxLoader";
import EditButton from "../../components/common/buttons/editButton";
import commonColor from "../../theme/commonColor";
import Modal from "../../components/common/modal/modal";
import ChatSearchComponent from "./ChatSearchComponent";
import Swotbutton from "../../components/common/buttons/swotbutton";
import NewGroupChatComponent from "./NewGroupChatComponent";
import ChatUserModel from "../models/chatUser";
import NewMessageComponent from "./NewMessageComponent";
import { ReactComponent as AddUserIcon } from "../../assets/icons/add-user.svg";

interface Props extends PropsFromRedux {}
class ChatScreenComponent extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.chatService = new ChatService();
    this.state = {
      componentListKey: Date.now(),
      chatGroups: null,
      chatMessages: [],
      popoverWidget: null,
      newGroupMembers: null,
      singleChatMember: null,
    };
  }

  public state: {
    componentListKey: number;
    chatGroups?: IChatGroup[] | null;
    activeChat?: IChatGroup;
    chatMessages: IChatMessage[];
    popoverWidget: any;
    newGroupMembers: ChatUserModel[] | null;
    singleChatMember: ChatUserModel | null;
  };

  private chatService: ChatService;

  public async componentDidMount(): Promise<void> {
    this.subscribeFirebaseListeners();
  }

  componentWillUnmount(): void {
    this.chatService.unSubscribeListeners();
  }

  subscribeFirebaseListeners() {
    this.chatService.listenToGroups(
      this.props.user.uid,
      (groups: IChatGroup[]) => {
        this.setState({ chatGroups: groups });
      }
    );
  }

  subscribeMessages(chatGroup: IChatGroup) {
    this.chatService.unSubscribeMessageListeners();
    this.setState({ chatMessages: [] });
    this.chatService.listenToActiveChat(
      this.props.user.uid,
      chatGroup.id,
      (messages: IChatMessage[]) => {
        this.setState({ chatMessages: messages });
        //TODO: update seene
        this.updateSeen(chatGroup);
      }
    );
  }

  updateSeen = (group: IChatGroup) => {
    const lastMessage = this.state.chatMessages.slice(-1)[0];
    const g = this.state.chatGroups?.find((g) => g.id === group.id);
    if (lastMessage && g && lastMessage.sentBy !== this.props.user.uid) {
      this.chatService.markSeen(g.id);
    }
  };

  render() {
    return (
      <MainPageWithMenu
        user={this.props.user}
        menuSelected={"inbox"}
        popoverContent={this.state.popoverWidget}
        closePopover={() => {
          this.closePopover();
        }}
      >
        <div className="h-full w-full flex relative flex-wrap">
          {/* <div className="min-h-40 lg:h-9/10 w-11/12 lg:w-3/4 p-2"> */}
          <div className="w-full h-full md:h-[calc(100%-60px)] md:w-1/2 lg:w-2/5 xl:w-3/10 p-2 absolute md:relative">
            <div className="w-full h-full bg-white rounded-2xl flex flex-col overflow-y-scroll">
              <div className="w-full flex flex-row justify-between  items-center p-4">
                <h1 className="">Inbox</h1>
                <div className="editCourseButtonStatic pl-6 pt-1 pr-3">
                  <button
                    className="focus:outline-none focus:shadow-lg"
                    onClick={() => {
                      this.startNewChat();
                    }}
                  >
                    <AddUserIcon
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        overflow: "hidden",
                        fill: commonColor.deepPink,
                      }}
                    />
                  </button>
                </div>
              </div>

              <div className="flex flex-col overflow-y-scroll">
                <ChatListComponent
                  key={this.state.componentListKey}
                  user={this.props.user}
                  chatService={this.chatService}
                  chatGroups={this.state.chatGroups ?? []}
                  activeChatId={this.state.activeChat?.id}
                  openChat={(chatGroup: IChatGroup) => {
                    //TODO: open active-chat screen
                    if (
                      !this.state.activeChat ||
                      this.state.activeChat.id !== chatGroup.id
                    ) {
                      this.setState({ activeChat: chatGroup });
                      this.subscribeMessages(chatGroup);
                    }
                  }}
                />
              </div>
              {!this.state.chatGroups && (
                <BoxLoader color={commonColor.deepGreen} />
              )}
              {this.state.chatGroups && this.state.chatGroups.length <= 0 && (
                <div className="text-white pt-16 md:pt-8 w-full">
                  <p className="w-full text-center text-black">
                    Currently there are no messages in your inbox
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full h-full md:h-[calc(100%-60px)] hidden md:block md:w-1/2 lg:w-3/5 xl:w-7/10 p-2">
            <div className="w-full h-full bg-white rounded-2xl">
              {this.state.activeChat ? (
                <div className="w-full h-full" key={this.state.activeChat.id}>
                  <ActiveChatComponent
                    user={this.props.user}
                    chatGroup={this.state.activeChat}
                    chatService={this.chatService}
                    chatMessages={this.state.chatMessages}
                  />
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  {/* this was appearing when user had no chats, so we have removed it */}
                  {/* {ChatListComponent ? <span>hello</span> : <span>bye</span>} */}
                </div>
              )}
            </div>
          </div>

          {this.state.activeChat && (
            <div className="w-full h-full md:hidden p-2 absolute">
              <div className="w-full h-full bg-white rounded-2xl">
                {/* <div className="active-chat-back-height">
                <Swotbutton text="Back" onClick={() => {
                  this.chatService.unSubscribeMessageListeners();
                  this.setState({ activeChat: undefined, chatMessages: []});
                }}/>
                </div> */}
                <div className="w-full h-full" key={this.state.activeChat.id}>
                  <ActiveChatComponent
                    user={this.props.user}
                    chatGroup={this.state.activeChat}
                    chatService={this.chatService}
                    chatMessages={this.state.chatMessages}
                    onClose={() => {
                      this.chatService.unSubscribeMessageListeners();
                      this.setState({
                        activeChat: undefined,
                        chatMessages: [],
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <FooterView textColorClass={"text-white"} />
        </div>

        <Modal
          isOpen={(this.state.newGroupMembers?.length ?? 0) > 0}
          onRequestClose={() => {
            this.setState({
              newGroupMembers: [],
            });
          }}
          isCloseButtonShown={true}
          setMaxSize={false}
        >
          <div className="w-full">
            <NewGroupChatComponent
              myUserId={this.props.user.uid}
              members={this.state.newGroupMembers ?? []}
              close={() => {
                this.setState({
                  newGroupMembers: [],
                });
              }}
            />
          </div>
        </Modal>

        <Modal
          isOpen={!!this.state.singleChatMember}
          isCloseButtonShown={true}
          setMaxSize={true}
          onRequestClose={() => {
            this.setState({
              singleChatMember: null,
            });
          }}
        >
          <div className="w-90v md:w-70v lg:w-50v max-h-80v text-center">
            {this.state.singleChatMember?.uid && (
              <NewMessageComponent
                myUserId={this.props.user.uid}
                toUserDetails={this.state.singleChatMember}
                close={() => {
                  this.setState({
                    singleChatMember: null,
                  });
                }}
              />
            )}
          </div>
        </Modal>
      </MainPageWithMenu>
    );
  }

  startNewChat() {
    // this.setState({
    //   openStartNewChat: true,
    // });
    this.showPopover(
      <div className="w-full flex justify-center">
        <ChatSearchComponent
          user={this.props.user}
          onCancel={() => {
            this.closePopover();
          }}
          onSubmit={(members) => {
            this.closePopover();
            if (members && members.length > 1) {
              this.setState({
                newGroupMembers: members,
              });
            } else if (members && members.length == 1) {
              this.setState({
                singleChatMember: members[0],
              });
            }
          }}
        />
      </div>
    );
  }

  closePopover() {
    this.setState({ popoverWidget: null });
  }

  showPopover(child) {
    this.setState({ popoverWidget: child });
  }
}

function mapStateToProps(state: ReduxState): { user: UserModel } {
  return {
    user: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChatScreenComponent);
