import { useEffect, useState } from "react";
import { ReactComponent as ToolsForStudyIcon } from "../../../assets/images/dashboard-tools-for-study.svg";
import { ReactComponent as GroupStudyIcon } from "../../../assets/images/dashboard-study-group.svg";
import { ReactComponent as TedTalksIcon } from "../../../assets/images/dashboard-ted-talks.svg";
import { ReactComponent as LearningmatrixIcon } from "../../../assets/images/dashboard-learning-matrix.svg";
import { ReactComponent as FindTutorIcon } from "../../../assets/images/dashboard-find-tutor.svg";

import { connect } from "react-redux";
import FooterView from "../../common/footerView";
import Swotbutton from "../../common/buttons/swotbutton";
import { isMobileDevice, useWindowSize } from "../../../utils/uiUtils";
import { useNavigate } from "react-router";
import MainPageWithMenu from "../../common/MainPageTemplate/mainPageWithMenu";
import contentful from "../../../config/contentful";
import UserActions from "../../../redux/user";
import { ACCOUNT_TYPES } from "../../../config/constants";
import { updateUser } from "../../../services/userManagement";

const SwotBuddyScreen = ({ user, saveUser }) => {
  const [width, height] = useWindowSize();
  const [motivationText, setMotivationText] = useState();
  const navigate = useNavigate();

  const fullDetailsUrl =
    user.accountType === "Tutor"
      ? "https://flipbooksw.web.app/"
      : "https://flipbook-learner.web.app/";
  const fullDetailPdfUrl =
    user.accountType === "Tutor"
      ? "https://firebasestorage.googleapis.com/v0/b/swotknot.appspot.com/o/assets%2FtutorStyleDetails.pdf?alt=media&token=51e1fd7e-0e76-4cd7-86a6-3942acefdb99.pdf"
      : "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/assets%2FlearnerStyle_student.pdf?alt=media&token=079e2151-0614-49ee-8213-e3ae0d111a71";

  useEffect(() => { }, [width, height]);

  useEffect(() => {
    getmotivationText();
  }, []);

  const getmotivationText = async () => {
    const response = await contentful.getEntries({
      content_type: "motivation",
    });
    console.log("Swotnews - get motivationText", response);
    if (response?.items?.length > 0) {
      const r = response.items[0].fields;
      const message = r?.message;
      setMotivationText(message);
    }
  };
  return (
    <MainPageWithMenu user={user} menuSelected={"swot-buddy"}>
      <div className="w-full p-4 h-full flex flex-row justify-center flex-wrap overflow-y-scroll max-w-3k">
        <div className="w-full h-[calc(100%-30px)] flex flex-col-reverse lg:flex-row justify-start">
          <div
            className={`w-full lg:w-2/3 xl:w-1/2 h-1/2 md:h-1/3 lg:h-full flex justify-center`}
          >
            <div
              className={`w-full max-w-[450px] md:max-w-full flex flex-row flex-wrap justify-center`}
            >
              <CardWidget
                title={"join a studygroup"}
                Icon={GroupStudyIcon}
                path={"/profile/messages"}
              />
              <CardWidget
                title={"find a tutor"}
                Icon={FindTutorIcon}
                path={"/find-tutors"}
                subtitle={"*this requires taking the quiz"}
              />
              <CardWidget
                title={"TED talks"}
                Icon={TedTalksIcon}
                path={"/ted-search"}
              />
              <CardWidget
                title={"tools for studying"}
                Icon={ToolsForStudyIcon}
                path={"/student-tools"}
              />
              {user.accountType == ACCOUNT_TYPES.SWOT_BUDDY &&
                !user.quizDone ? (
                <CardWidget
                  title={"personalized"}
                  Icon={LearningmatrixIcon}
                  onClick={() => {
                    const updatedUser = {
                      ...user,
                      swotBuddySetup: "personalized",
                    };
                    saveUser(updatedUser);
                    updateUser(updatedUser, true);
                    navigate("/dashboard");
                  }}
                />
              ) : (
                <CardWidget
                  title={"learning matrix"}
                  Icon={LearningmatrixIcon}
                  onClick={() => {
                    if (isMobileDevice()) {
                      window.open(fullDetailPdfUrl, "_blank", "fullscreen=yes");
                    } else {
                      window.open(fullDetailsUrl, "_blank");
                    }
                  }}
                />
              )}
              <CardWidget
                className="font-bold"
                title={"stay motivated"}
                description={motivationText ?? ""}
              />

            </div>
          </div>

          <div className="w-full lg:w-1/3 xl:w-1/2 h-1/2 md:h-2/3 lg:h-full flex flex-row flex-wrap justify-center">
            <BigCardWidget />
          </div>
        </div>
      </div>
      <FooterView />
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwotBuddyScreen);

const CardWidget = ({
  title,
  subtitle,
  Icon,
  img,
  description,
  path,
  onClick,
  bgColor,
}) => {
  let navigate = useNavigate();

  return (
    <div
      className={`${isMobileDevice()
          ? "h-1/2 w-6/12 p-2"
          : "h-1/2 w-6/12 md:h-full md:w-1/6 lg:h-1/2 lg:w-1/3 p-2"
        }`}
    >
      <div
        className={`darker_shadow w-full h-full flex flex-col justify-end overflow-hidden rounded lg:rounded-2xl ${path || onClick ? "cursor-pointer" : ""
          }`}
        onClick={() => {
          if (path) {
            navigate(path);
          }
          if (onClick) {
            onClick();
          }
        }}
      >
        <div
          className={`w-full grow flex justify-center ${bgColor ? bgColor : !Icon ? "bg-[#C6AEDC]" : ""
            }`}
        >
          {Icon ? (
            <Icon
              className="contain rounded-xl h-full"
              style={{
                height: "100%",
                display: "block",
                objectFit: "contain",
              }}
            />
          ) : img ? (
            <img
              src={img}
              alt=""
              className="rounded-xl h-full p-4"
              style={{
                height: "100%",
                display: "block",
                objectFit: "contain",
              }}
            />
          ) : (
            <div className="w-full py-4 px-5 flex place-items-center text-[0.50rem]  md:text-sm lg:text-base h-full text-center">
              {description}
            </div>
          )}
        </div>
        <div className="w-full flex justify-center bg-[#4F5371] py-2 lg:py-6 px-2 text-center">
          <div className="text-white text-[0.50rem] md:text-xs lg:text-sm">
            {title}

            <p className="text-gray-200 text-[0.50rem] md:text-xs lg:text-sm hidden md:block">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const txt = `
  It is my pleasure to welcome you to our study partnership, powered by l3arn and facilitated by me, ChatGPT - your personal study partner. I am an Al language model, trained on a vast amount of information and equipped with advanced language processing capabilities to assist you in your studies.
  I am excited to join you on your academic journey, where we will work together to achieve your learning goals. Whether you need help with exam preparation, research, writing, or problem-solving, I am here to provide personalized support and assistance.
  With l3arns's innovative education platform and my expertise, we will make learning more accessible and enjoyable for you. Together, we can create a personalized learning experience that caters to your unique needs and helps you succeed.
  So let's get started! I'm eager to learn more about you and help you achieve academic excellence.
  Best regards,
  ChatGPT`;

const BigCardWidget = ({ onClick }) => {
  let navigate = useNavigate();
  return (
    <div className="h-full w-full p-2">
      <div
        className={`darker_shadow  w-full h-full flex flex-col justify-end overflow-hidden rounded-lg lg:rounded-2xl`}
      >
        <div className="w-full grow bg-white overflow-y-scroll p-2">
          <div className="w-full text-sm whitespace-pre-line select-none px-4 md:px-6 2xl:px-8 mt-4 md:mt-6 2xl:mt-8">
            {txt}
          </div>
        </div>
        <div className="w-full flex justify-end bg-[#4F5371] py-1 md:py-2 px-4">
          <Swotbutton
            text="lets start!"
            onClick={() => {
              navigate("/chat-prompt");
            }}
          />
        </div>
      </div>
    </div>
  );
};
