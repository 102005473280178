import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import UserInfoPanel from "../../tutor/profile/userInfoPanel";
import TutorTypePanel from "../../tutor/profile/tutorTypePanel";
import WalletPanel from "./walletPanel";
import FooterView from "../../common/footerView";

const StudentProfile = () => {
  return (
    <div className="h-full w-full flex justify-start flex-wrap overflow-y-scroll">
      <div className="w-full xl:w-1/2 flex flex-col max-w-5xl mt-5 relative">
        <div
          className="w-full px-2 pt-8 xl:pt-0 xl:max-h-36 min-h-33r xl:h-44r"
        >
          <UserInfoPanel />
        </div>
      </div>
      <div className="w-full xl:w-1/2 flex flex-col max-w-5xl mt-5 relative">
        <div
          className="w-full px-2 pt-8 xl:pt-0 xl:max-h-36 min-h-33r xl:h-44r"
        >
          <WalletPanel />
        </div>
      </div>

      <div className="w-full py-10 flex justify-center items-center">
        <FooterView />
      </div>
    </div>
  );
};

export default StudentProfile;
