import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import commonColor from "../../../theme/commonColor";
import { auth, manageUserStatus } from "../../../config/firebase";
import { doc, collection, setDoc, onSnapshot } from "firebase/firestore";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import { toast } from "react-toastify";
import "./sideMenu.css";

import { ReactComponent as Home } from "../../../assets/icons/005-home.svg";
import { ReactComponent as Rope } from "../../../assets/icons/006-rope.svg";
import { ReactComponent as Box } from "../../../assets/icons/box.svg";
import { ReactComponent as BlackBoard } from "../../../assets/icons/blackboard.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/003-calendar.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout sk.svg";
import { ReactComponent as FindTutors } from "../../../assets/icons/001-search.svg";
import { ReactComponent as Tools } from "../../../assets/icons/002-gear.svg";
import { ReactComponent as IdeaIcon } from "../../../assets/icons/idea.svg";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as NGOIcon } from "../../../assets/icons/ngo.svg";
import { ReactComponent as Spotify } from "../../../assets/icons/spotify.svg";
import { ReactComponent as FAQ } from "../../../assets/icons/faq.svg";
import { ReactComponent as Refunds } from "../../../assets/icons/open-book.svg";
import { ReactComponent as Tester } from "../../../assets/icons/001-user.svg";
import { ReactComponent as Ambassador } from "../../../assets/icons/reading-book.svg";
import { ReactComponent as ImporterIcon } from "../../../assets/icons/download-icon.svg";
import { ReactComponent as MentalHealth } from "../../../assets/icons/mentalHealth.svg";
import { ReactComponent as UserManagementIcon } from "../../../assets/icons/user_management.svg";
import { ReactComponent as UserGroupIcon } from "../../../assets/icons/users-icon.svg";
import { ReactComponent as ArtificialIcon } from "../../../assets/icons/artificial-icon.svg";
import { ReactComponent as AdvancedIcon } from "../../../assets/icons/advanced-icon.svg";
import { ReactComponent as More } from "../../../assets/icons/menu_more.svg";
import { firestore } from "../../../config/firebase";
import swal from "sweetalert2";

import { ACCOUNT_TYPES, SHOW_H5P } from "../../../../src/config/constants";
import { useNavigate } from "react-router-dom";

const tooltipDelay = 500;

const SideMenu = ({
  user,
  selected,
  logout,
  saveUser,
  unreadChatCount,
  isTourOpen,
  lockToSubscription,
}) => {
  let navigate = useNavigate();

  const [selectedMenuItem, setSelectedMenuItem] = useState(selected);
  const [tourIsOpen, setTourIsOpen] = useState(false);
  const tourOpenStateRef = React.useRef(tourIsOpen);

  // ... more button
  const [isGroupMenuOpen, setGroupMenuIsOpen] = useState(false);
  const [isExtraMenuOpen, setExtraMenuIsOpen] = useState(false);
  const handleClickOutside = (event) => {
    const element = event.target.closest(".my-component");
    if (!element && !tourOpenStateRef.current) {
      setExtraMenuIsOpen(false);
      setGroupMenuIsOpen(false);
    }
  };

  // .. more button end

  useEffect(() => {
    if (isTourOpen && !user.tourPopoverShown) {
      tourOpenStateRef.current = true;
      setTourIsOpen(true);
      setExtraMenuIsOpen(true);
      setGroupMenuIsOpen(true);
    } else if (tourOpenStateRef.current) {
      tourOpenStateRef.current = false;
      setTourIsOpen(false);
      setExtraMenuIsOpen(false);
      setGroupMenuIsOpen(false);
    }
    // Close the group menu if isTourOpen is false
    if (!isTourOpen) {
      setGroupMenuIsOpen(false);
    }
  }, [isTourOpen]);

  useEffect(() => {
    console.log(selectedMenuItem);
    // if (user.uid && selectedMenuItem === "home") {
    // if(user.uid && !user.tourPopoverShown && selectedMenuItem === "home") {
    // setExtraMenuIsOpen(true);
    // }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [selectedMenuItem]);

  useEffect(() => {
    manageUserStatus();
    console.log("SideMenu", user);

    if (user?.accountType === "Tutor") {
      let tutorStatusFirestoreRef = doc(
        firestore,
        "/call-request/" + user?.uid
      );
      onSnapshot(tutorStatusFirestoreRef, function (document) {
        const fetchedNotifications = document.data();
        console.log("Call Request Recevied:", fetchedNotifications);
        if (fetchedNotifications && fetchedNotifications.state === "pending") {
          swal
            .fire({
              title: "Call Request",
              text: `Student ${fetchedNotifications.student_username} requested a call. Proceed to Call View?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes proceed",
              cancelButtonText: "Ignore call",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                await setDoc(
                  doc(collection(firestore, "call-request"), user?.uid),
                  {
                    state: "attended",
                  },
                  { merge: true }
                )
                  .then((e) => {
                    window.location.href = fetchedNotifications.tutor_meet_link;
                  })
                  .then((e) => {
                    console.log("");
                  });
              } else {
                await setDoc(
                  doc(collection(firestore, "call-request"), user?.uid),
                  {
                    state: "ignored",
                  },
                  { merge: true }
                );
              }
            });
        }
      });
    }
  }, [user]);

  const styleMenuItem = (menuItem) => {
    if (selected === menuItem) {
      return {};
    } else {
      return {
        width: "1.5rem",
        height: "1.5rem",
        overflow: "hidden",
        fill: commonColor.deepGreen,
      };
    }
  };

  const allowToNavigate = () => {
    console.log("USER::", user);
    if (
      (user.accountType === "Tutor" || user.accountType === "GuestTutor") &&
      (!user.setupStatus ||
        user.setupStatus == "pending" ||
        user.setupStatus == "rejected")
    ) {
      console.log("Can't do that!"); // Print a message to console that the action is prohibited
      const type = toast.TYPE.ERROR;
      let msg =
        "Please complete the tutor setup before navigating to your profile.";
      if (user.setupStatus == "pending")
        msg = "Please wait for approval before navigating to your profile.";
      if (user.setupStatus == "rejected")
        msg = "Your account has been rejected, please contact us.";

      toast(msg, { type: toast.TYPE[type] }); // Displays an error toast on the page instead of navigating to "profile"
      return false;
    } else {
      return true;
    }
  };

  const navigateTo = (path, params) => {
    if (lockToSubscription == true) {
      //TODO:
      swal
        .fire({
          title: "Subscription required Request",
          text: `You are not subscribed. You need to goto profile screen and subscribe`,
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Subscribe",
          cancelButtonText: "Cancel",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            navigate("/profile");
          }
        });
    } else {
      navigate(path, params ?? {});
    }
  };
  const ProfileMenuItem = () => (
    <>
      <button
        className="flex justify-center -translate-x-1 md:translate-x-0 scale-125 items-center p-0.5 focus:outline-none h-8 w-8 sm:h-10 sm:w-10 shadow-lg rounded-full relative"
        onClick={() => {
          // Note from Ian: Not sure what a "GuestTutor" account is so included it just in case
          if (allowToNavigate()) {
            console.log("Go to my profile!");
            navigateTo("/profile", { state: { userId: user.uid } });
          }
        }}
        data-tip
        data-for="profile"
        data-tut="reactour__menu-profile"
      >
        {user.photoUrl ? (
          <img
            src={user.photoUrl}
            alt="userPhoto"
            className="object-cover w-full h-full rounded-full shadow-lg "
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full p-1 bg-gray-300 rounded-full shadow-md">
            <UserIcon
              className="h-full"
              style={{
                fill:
                  !selectedMenuItem || selectedMenuItem === "inbox"
                    ? commonColor.deepPink
                    : commonColor.deepGreen,
              }}
            />
          </div>
        )}
        {user.ngoUser && (
          <div
            className="absolute bottom-0 right-0 w-4 h-4 rounded-full"
            style={{
              background: "#ffffffaa",
            }}
          >
            <NGOIcon
              className="w-4 h-4"
              style={{
                fill: commonColor.sourCherry,
              }}
            />
          </div>
        )}

        {unreadChatCount > 0 && (
          <div
            className="absolute top-0 right-0 w-2 h-2 rounded-full"
            style={{ backgroundColor: commonColor.deepPink }}
          ></div>
        )}
      </button>
      <ReactTooltip id="profile" place="right" delayShow={tooltipDelay}>
        Profile
      </ReactTooltip>
    </>
  );

  const HomeMenuItem = () => (
    <>
      <button
        // className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
        // ${selectedMenuItem === "home" && "shadow-lg"} h-full lg:h-auto lg:w-full`}
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
      ${selectedMenuItem === "home" && "shadow-lg"} h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("home");
          navigateTo("/dashboard");
        }}
        data-tip
        data-for="dashboard"
        data-tut="reactour__menu-home"
      >
        <Home
          style={{
            fill:
              selectedMenuItem === "home"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="dashboard" place="right" delayShow={tooltipDelay}>
        Dashboard
      </ReactTooltip>
    </>
  );

  const FeedMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
${selectedMenuItem === "rope" && "shadow-lg"}  h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("rope");
          navigateTo("/guest-feed");
        }}
        data-tip
        data-for="feed"
        style={{
          aspectRatio: "1 / 1",
        }}
        data-tut="reactour__menu-feeds"
      >
        <Rope
          style={{
            fill:
              selectedMenuItem === "rope"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="feed" place="right" delayShow={tooltipDelay}>
        Feed
      </ReactTooltip>
    </>
  );

  const LibraryMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
${selectedMenuItem === "library" && "shadow-lg"}  h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("library");
          navigateTo("/courses");
        }}
        data-tip
        data-for="courses"
        style={{
          fill:
            selectedMenuItem === "library"
              ? commonColor.deepPink
              : commonColor.deepGreen,
        }}
        data-tut="reactour__menu-courses"
      >
        <BlackBoard
          style={{
            fill:
              selectedMenuItem === "library"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="courses" place="right" delayShow={tooltipDelay}>
        Courses
      </ReactTooltip>
    </>
  );

  const SpotifyMenuItem = () => (
    <>
      <button
        data-tip
        data-for="spotify"
        className="w-8 h-8 p-1 rounded-lg sm:p-3 focus:outline-none sm:h-12 sm:w-12"
        onClick={() => {
          if (allowToNavigate()) {
            window.open(
              "https://open.spotify.com/user/ct6w5u57cw3dmrog342s2ysmh?si=ea30e0a814cb46b7",
              "_blank"
            );
          }
        }}
        style={{
          aspectRatio: "1 / 1",
        }}
        data-tut="reactour__menu-spotify"
      >
        <Spotify
          style={{
            fill:
              // selectedMenuItem === "findTutors"
              //   ? commonColor.deepPink
              commonColor.spotifyGreen,
          }}
        />
      </button>
      <ReactTooltip id="spotify" place="right" delayShow={tooltipDelay}>
        Spotify
      </ReactTooltip>
    </>
  );

  // const HealthMenuItem = () => (
  //   <>
  //     <button
  //       className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
  //     ${selectedMenuItem === "MentalHealth" && "shadow-lg"
  //         } h-8 w-8 sm:h-12 sm:w-12`}
  //       onClick={() => {
  //         if (allowToNavigate()) {
  //           setSelectedMenuItem("MentalHealth");
  //           navigate("/mental-health");
  //         }
  //       }}
  //       data-tip
  //       data-for="MentalHealth"
  //       data-tut="reactour__menu-MentalHealth"
  //     >
  //       <MentalHealth
  //         style={{
  //           fill:
  //             selectedMenuItem === "MentalHealth"
  //               ? commonColor.deepPink
  //               : commonColor.deepGreen,
  //         }}
  //       />
  //     </button>
  //     <ReactTooltip id="MentalHealth" place="right" delayShow={tooltipDelay}>
  //       Mental Health
  //     </ReactTooltip>
  //   </>
  // );

  const FindTutorMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
${selectedMenuItem === "findTutors" && "shadow-lg"}  h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("findTutors");
          navigateTo("/find-tutors");
        }}
        data-tip
        data-for="search"
        style={{
          aspectRatio: "1 / 1",
        }}
        data-tut="reactour__menu-findtutors"
      >
        <FindTutors
          style={{
            fill:
              selectedMenuItem === "findTutors"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="search" place="right" delayShow={tooltipDelay}>
        Find tutors
      </ReactTooltip>
    </>
  );

  const RefundMenuItem = () => (
    <>
      <button
        data-tip
        data-for="refunds"
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
            ${
              selectedMenuItem === "refunds" && "shadow-lg"
            } h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("refunds");
          navigateTo("/refund");
        }}
        style={{
          aspectRatio: "1 / 1",
        }}
      >
        <Refunds
          style={{
            fill:
              selectedMenuItem === "refunds"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="refunds" place="right" delayShow={tooltipDelay}>
        Refunds
      </ReactTooltip>
    </>
  );

  const BetaTesterMenuItem = () => (
    <>
      <button
        data-tip
        data-for="betatester"
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
            ${
              selectedMenuItem === "betatester" && "shadow-lg"
            } h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("betatester");
          navigateTo("/betatester");
        }}
        style={{
          aspectRatio: "1 / 1",
        }}
      >
        <Tester
          style={{
            fill:
              selectedMenuItem === "betatester"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="betatester" place="right" delayShow={tooltipDelay}>
        Beta Testers
      </ReactTooltip>
    </>
  );

  const AmbassadorsMenuItem = () => (
    <>
      <button
        data-tip
        data-for="ambassadors"
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
            ${
              selectedMenuItem === "ambassadors" && "shadow-lg"
            } h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("ambassadors");
          navigateTo("/ambassadors");
        }}
        style={{
          aspectRatio: "1 / 1",
        }}
      >
        <Ambassador
          style={{
            width: "25px",
            height: "25px",
            fill:
              selectedMenuItem === "ambassadors"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="ambassadors" place="right" delayShow={tooltipDelay}>
        Ambassadors
      </ReactTooltip>
    </>
  );

  const DataImporterMenuItem = () => (
    <>
      <button
        data-tip
        data-for="importer"
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
            ${
              selectedMenuItem === "importer" && "shadow-lg"
            } h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("importer");
          navigateTo("/importer");
        }}
        style={{
          aspectRatio: "1 / 1",
        }}
      >
        <ImporterIcon
          style={{
            fill:
              selectedMenuItem === "importer"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="importer" place="right" delayShow={tooltipDelay}>
        Data Importer
      </ReactTooltip>
    </>
  );

  const UserManagementMenuItem = () => (
    <>
      <button
        data-tip
        data-for="user-management"
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
            ${
              selectedMenuItem === "user-management" && "shadow-lg"
            } h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("user-management");
          navigateTo("/user-management");
        }}
        style={{
          aspectRatio: "1 / 1",
        }}
      >
        <UserManagementIcon
          style={{
            fill:
              selectedMenuItem === "user-management"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="user-management" place="right" delayShow={tooltipDelay}>
        User Management
      </ReactTooltip>
    </>
  );

  const MyContentsMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
${selectedMenuItem === "h5p" && "shadow-lg"} h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("h5p");
          navigateTo("/content-management");
        }}
        data-tip
        data-for="h5p"
        data-tut="reactour__menu-h5p"
      >
        <IdeaIcon
          style={{
            fill:
              selectedMenuItem === "h5p"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="h5p" place="right" delayShow={tooltipDelay}>
        My Content
      </ReactTooltip>
    </>
  );

  const FaqMenuItem = () => (
    <>
    {/* FAQ removed for now, keeping the code will remove after discussions with POs */}
      {/* <button
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
      ${selectedMenuItem === "faqs" && "shadow-lg"} h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          if (allowToNavigate()) {
            setSelectedMenuItem("faqs");
            navigate("/faqs");
          }
        }}
        data-tip
        data-for="faqs"
        data-tut="reactour__menu-faqs"
      >
        <FAQ
          style={{
            fill:
              selectedMenuItem === "faqs"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button> */}
      <ReactTooltip id="faqs" place="right" delayShow={tooltipDelay}>
        FAQ
      </ReactTooltip>
    </>
  );

  const groupMenuItem = () => {
    return (
      <>
        <button
          // className={`more p-1 sm:p-2 hover:shadow-xl focus:outline-none rounded-lg shadow-lg h-8 w-8 sm:h-12 sm:w-12 `}
          className={`more p-1 sm:p-2 hover:shadow-xl focus:outline-none rounded-lg ${
            (selectedMenuItem === "swot-buddy" ||
              selectedMenuItem === "chat-prompt") &&
            "shadow-lg"
          } h-8 w-8 sm:h-12 sm:w-12`}
          onClick={() => {
            setGroupMenuIsOpen(!isGroupMenuOpen);
            setExtraMenuIsOpen(false);
          }}
          data-tip
          data-for="group"
          data-tut=""
        >
          <UserGroupIcon
            style={{
              fill:
                selectedMenuItem === "swot-buddy" ||
                selectedMenuItem === "chat-prompt"
                  ? commonColor.deepPink
                  : commonColor.deepGreen,
            }}
          />
        </button>
        <ReactTooltip id="more" place="right" delayShow={tooltipDelay}>
          Group...
        </ReactTooltip>
      </>
    );
  };

  const SwotBuddyMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-2 hover:shadow-xl focus:outline-none rounded-lg
${selectedMenuItem === "swot-buddy" && "shadow-lg"} h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          if (allowToNavigate()) {
            setSelectedMenuItem("swot-buddy");
            navigate("/swot-buddy");
          }
        }}
        data-tip
        data-for="swot-buddy"
        data-tut="reactour__menu-swotbuddy"
      >
        <AdvancedIcon
          style={{
            aspectRatio: "1/1",
            fill:
              selectedMenuItem === "swot-buddy"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="tools" place="right" delayShow={tooltipDelay}>
        Swot Buddy
      </ReactTooltip>
    </>
  );

  const ChatGptMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-2 hover:shadow-xl focus:outline-none rounded-lg
${selectedMenuItem === "chat-prompt" && "shadow-lg"} h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          if (allowToNavigate()) {
            setSelectedMenuItem("chat-prompt");
            navigateTo("/chat-prompt");
          }
        }}
        data-tip
        data-for="chatgpt"
        data-tut="reactour__menu-chatgpt"
      >
        <ArtificialIcon
          style={{
            aspectRatio: "1/1",
            fill:
              selectedMenuItem === "chat-prompt"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="chatgpt" place="right" delayShow={tooltipDelay}>
        Chat Prompt
      </ReactTooltip>
    </>
  );

  const moreMenuItem = () => {
    return (
      <>
        <button
          className={`more p-1 sm:p-2 hover:shadow-xl focus:outline-none rounded-lg ${
            (selectedMenuItem === "ted-search" ||
              selectedMenuItem === "tutorTools" ||
              selectedMenuItem === "studentTools") &&
            "shadow-lg"
          } h-8 w-8 sm:h-12 sm:w-12`}
          onClick={() => {
            setExtraMenuIsOpen(!isExtraMenuOpen);
            setGroupMenuIsOpen(false);
          }}
          data-tip
          data-for="more"
          data-tut=""
        >
          <More
            style={{
              fill: [
                "more",
                "ted-search",
                "tutorTools",
                "studentTools",
              ].includes(selectedMenuItem)
                ? commonColor.deepPink
                : commonColor.deepGreen,
            }}
          />
        </button>
        <ReactTooltip id="more" place="right" delayShow={tooltipDelay}>
          More...
        </ReactTooltip>
      </>
    );
  };

  const CalendarMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-3 hover:shadow-xl focus:outline-none rounded-lg
      ${
        selectedMenuItem === "calendar" && "shadow-lg"
      }  h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          setSelectedMenuItem("calendar");
          navigateTo("/calendar");
        }}
        data-tip
        data-for="calendar"
        style={{
          aspectRatio: "1 / 1",
        }}
        data-tut="reactour__menu-calendar"
      >
        <Calendar
          style={{
            fill:
              selectedMenuItem === "calendar"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="calendar" place="right" delayShow={tooltipDelay}>
        Calendar
      </ReactTooltip>
    </>
  );

  const ToolMenuItem = () => (
    <>
      <button
        className={`p-1 sm:p-2 hover:shadow-xl focus:outline-none rounded-lg
${
  ["tutorTools", "studentTools", "advanceTools"].includes(selectedMenuItem) &&
  "shadow-lg"
} h-8 w-8 sm:h-12 sm:w-12`}
        onClick={() => {
          if (allowToNavigate()) {
            setSelectedMenuItem(
              user.accountType === "Tutor" ? "tutorTools" : "studentTools"
            );
            navigateTo(
              user.accountType === "Tutor" ? "/tutor-tools" : "/student-tools"
            );
          }
        }}
        data-tip
        data-for="tools"
        data-tut="reactour__menu-tools"
      >
        <Rope
          style={{
            aspectRatio: "1/1",
            fill: ["tutorTools", "studentTools"].includes(selectedMenuItem)
              ? commonColor.deepPink
              : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="tools" place="right" delayShow={tooltipDelay}>
        News Feeds
      </ReactTooltip>
    </>
  );

  const LogoutMenuItem = () => (
    <>
      <button
        className="w-8 h-8 p-1 rounded-lg sm:p-3 focus:outline-none sm:h-12 sm:w-12"
        onClick={() => {
          // saveUser(null);
          // auth.signOut();
          // navigate("/");
          logout && logout();
        }}
        data-tip
        data-for="logout"
        // style={{
        //   aspectRatio: "1 / 1",
        // }}
        data-tut="reactour__menu-logout"
      >
        <Logout
          style={{
            fill:
              selectedMenuItem === "logout"
                ? commonColor.deepPink
                : commonColor.deepGreen,
          }}
        />
      </button>
      <ReactTooltip id="logout" place="right" delayShow={tooltipDelay}>
        Logout
      </ReactTooltip>
    </>
  );

  return (
    <div
      // menu_shadow bg-white w-full h-full flex flex-row lg:flex-col items-center
      // justify-between lg:justify-start  pl-4 pr-2 py-2 lg:px-2 lg:py-8 lg:space-y-4 2xl:space-y-8 lg:overflow-y-scroll
      className="flex flex-row items-center justify-between w-full h-full py-2 pl-4 pr-2 bg-white my-component menu_shadow lg:flex-col lg:justify-start lg:px-2 lg:py-8 lg:space-y-4 2xl:space-y-8"
      style={{
        borderRadius: "1.5rem",
        // overflow: "hidden",
      }}
    >
      <ProfileMenuItem />

      <HomeMenuItem />

      {/* TODO */}

      {user.accountType === "SwotAdmin" && (
        <>
          <RefundMenuItem />

          <BetaTesterMenuItem />

          <AmbassadorsMenuItem />

          <DataImporterMenuItem />

          <UserManagementMenuItem />
        </>
      )}

      {user.accountType === "GuestStudent" && <FeedMenuItem />}

      {((user.accountType === "Tutor" && user.setupStatus === "approved") ||
        user.accountType === ACCOUNT_TYPES.STUDENT ||
        user.accountType === ACCOUNT_TYPES.SWOT_BUDDY ||
        user.accountType === "GuestTutor") && <LibraryMenuItem />}

      {(user.accountType === ACCOUNT_TYPES.STUDENT ||
        user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) && <FindTutorMenuItem />}

      {/* {user.accountType !== "SwotAdmin" && <HealthMenuItem />} */}

      {SHOW_H5P &&
        user.accountType === "Tutor" &&
        user.setupStatus === "approved" && <MyContentsMenuItem />}

      <FaqMenuItem />

      {user.accountType == ACCOUNT_TYPES.STUDENT ||
      user.accountType === ACCOUNT_TYPES.SWOT_BUDDY ? (
        <div className="relative moreBtn_wrapper">
          {groupMenuItem()}

          <div
            className={`${
              isGroupMenuOpen ? "flex" : "hidden"
            } justify-center lg:flex-row flex-col items-center lg:py-10 px-2 py-12 more_icons shadow-lg focus:outline-none bg-white`}
          >
            {/* Inside More */}
            <ChatGptMenuItem />

            <SwotBuddyMenuItem />
          </div>
        </div>
      ) : (
        <ChatGptMenuItem />
      )}

      <ToolMenuItem />
      <LogoutMenuItem />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
