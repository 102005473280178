import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import UserInfoPanel from "./userInfoPanel";
import TutorTypePanel from "./tutorTypePanel";
import WalletPanel from "./walletPanel";
import { getTutorStripeStatus } from "../../../services/paymentsManagement";
import { fetchCurrentUser } from "../../../services/userManagement";
import { STRIPE_REG_STATUSES } from "../../../config/constants";
import { useParams, useSearchParams } from "react-router-dom";
import LoaderModal from "../../common/modal/loaderModal";
import Swal from "sweetalert2";
import FooterView from "../../common/footerView";

const TutorProfile = ({ user, saveUser, location }) => {
  // const searchParams = new URLSearchParams(location?.search);
  let [searchParams, _] = useSearchParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const stripeStatus = searchParams.get("stripe");
    if (stripeStatus) {
      checkStatus(stripeStatus);
    } else {
      saveUserInAppState();
    }
  }, []);

  const checkStatus = async (stripeStatus) => {
    try {
      setLoading(true);
      const result = await getTutorStripeStatus(user.uid);
      saveUserInAppState(stripeStatus);
    } catch (e) { }
  };

  const saveUserInAppState = async (stripeStatus) => {
    const userSnapshot = await fetchCurrentUser();
    setLoading(false);
    const fetchedUser = userSnapshot.data();
    if (fetchedUser) {
      saveUser(fetchedUser);

      if (!fetchedUser?.ngoUser && stripeStatus) {
        if (fetchedUser.stripeStatus === STRIPE_REG_STATUSES.APPROVED) {
          Swal.fire({
            title: "Success!",
            text: "You have registered for stripe",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else if (fetchedUser.stripeStatus === STRIPE_REG_STATUSES.INACTIVE) {
          Swal.fire({
            title: "Missing Info!",
            text: "Some information is missing in your stripe account. Please update to make stripe active",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      }
    }
  };

  return (
    <div className="h-full w-full flex justify-start flex-wrap overflow-y-scroll">
      <div className="w-full xl:w-1/2 flex flex-col max-w-5xl mt-10 relative">
        <div
          className="w-full px-2 pt-8 xl:pt-0 xl:max-h-36 min-h-33r xl:h-44r"

        >
          <UserInfoPanel />
        </div>
      </div>
      <div className="w-full xl:w-1/2 flex flex-col max-w-5xl mt-10 relative">
        <div className="w-full px-2 pt-8 xl:pt-0 xl:max-h-36 min-h-33r xl:h-44r">
          <WalletPanel />
        </div>
      </div>
      <div className="w-full py-10 flex justify-center items-center"><FooterView /></div>
      <LoaderModal isOpen={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorProfile);
